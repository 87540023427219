import "@hotwired/turbo-rails";
import '../controllers/index';
import toggleTheme from '&/dark-mode.js';
import UIKit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIKit.use(Icons);

window.toggleTheme = toggleTheme;

