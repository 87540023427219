import {Controller} from "@hotwired/stimulus"
import UIKit from "uikit"
export default class extends Controller {
    static targets = ["notification"]

    PROPS = ['notificationStatus', 'notificationTimeout', 'notificationGroup', 'notificationPos'];

    connect() {
        Array.from(this.notificationTargets).forEach((elem) => {
            let options = { message: elem.innerHTML };
            const props = this.PROPS.reduce((hash, prop) => {
                if(elem.dataset[prop])
                    hash[prop.replace('notification','').toLowerCase()] = elem.dataset[prop];
                return hash
            }, {});


            if(props['timeout'])
                props['timeout'] = Number(props['timeout'])

            options = { ...options, ...props };
            UIKit.notification(options);
            elem.remove();
        });
    }
}