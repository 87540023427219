export default function () {
    const themeKey = 'color-theme';
    const darkClass = 'dark'
    const lightClass = 'light';
    let currentTheme = localStorage.getItem(themeKey);
    if (currentTheme === null)
        currentTheme = document.documentElement.classList.contains(darkClass) ? darkClass : lightClass;

    if (currentTheme === darkClass) {
        document.documentElement.classList.remove(darkClass)
        localStorage.setItem(themeKey, lightClass);
    } else {
        document.documentElement.classList.add(darkClass)
        localStorage.setItem(themeKey, darkClass);
    }
};

