import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static target = ["filter"]
    static targets = ["excercise"];

    connect() {

    }

    onFilterChange(event) {
        const matcher = new RegExp(event.target.value, 'i')
        Array.from(this.excerciseTargets).forEach((item) => {
            console.log('item', item);
            if (item.dataset.excercisesValue.match(matcher)) {
                item.removeAttribute('hidden');
            } else {
                item.setAttribute('hidden', true);
            }
        });
    }


}
