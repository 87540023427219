import {Controller} from "@hotwired/stimulus"
import {notification} from "uikit";

export default class extends Controller {
    static targets = ["excercise"];
    static values = {excerciseOrderUrl: String}

    connect() {
    }

    onMoved() {
        const order = Array.from(this.excerciseTargets).reduce((memo, item, idx) => {
            const excerciseId = item.dataset.excerciseId;
            return {...memo, [idx]: {id: Number(excerciseId), number: idx}}
        }, {})
        const body = {
            routine: {
                routine_excercises_attributes: order
            }
        };

        fetch(this.excerciseOrderUrlValue, {
            "method": "PATCH",
            "body": JSON.stringify(body),
            "headers": {
                'X-CSRF-Token': this.csrfToken,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (!response.ok) throw new Error('Error Inesperado');
            return response.text()
        })
            .then((html) => Turbo.renderStreamMessage(html))
            .catch((error) => {
                notification(error.message, {status: 'danger', post: 'top-center'})
            });

    }

    get csrfToken() {
        return document.querySelector("[name='csrf-token']").content;
    }
}
